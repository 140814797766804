import React, { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import { useApiClient } from "@workpoint/components/lib/clients/ApiProvider"
import { useAppTitle } from "../../store/hooks/useAppTitle"
import {
    ClientType,
    ISPUserCultureAndTime,
    RuntimeContext
} from "@workpoint/components/lib/models/ProcessInstance"
import { Process } from "@workpoint/components/lib/components/process/Process"
import { eventBus, EventItem } from "@workpoint/components/lib/clients/EventBus"
import { getspHostUrl } from "../../util/common"
import { UnauthorizedError } from "@workpoint/components/lib/components/common/UnauthorizedError"
import { GenericError } from "@workpoint/components/lib/components/common/GenericError"
import { useMsal } from "@azure/msal-react"

export const ProcessViewer: React.FC = () => {
    const intl = useIntl()
    const { accounts, instance } = useMsal()

    const { apiClient } = useApiClient()

    let element

    var urlParams = new URLSearchParams(window.location.search.toLowerCase())
    const solutionUrl = getspHostUrl() ?? ""
    const businessModuleId = urlParams.get("businessmoduleid") ?? ""
    const entityIdParam = urlParams.get("entityid") ?? ""
    const entityId = entityIdParam ? parseInt(entityIdParam) : undefined
    const locale = urlParams.get("locale")
    const [runtimeContext, setRuntimeContext] = useState<RuntimeContext>()
    const [error, setError] = useState<any>()

    useAppTitle(
        intl.formatMessage({
            id: "process-title",
            defaultMessage: "Process"
        })
    )

    useEffect(() => {
        if (solutionUrl) {
            apiClient
                .getWP(`/Users/UserCultureAndTimeZoneInfo`)
                .then((userCulture: ISPUserCultureAndTime) => {
                    setRuntimeContext({
                        currentUICulture: locale ?? userCulture.CurrentUICulture,
                        currentCulture: userCulture.CurrentCulture,
                        solutionUrl,
                        businessModuleId,
                        entityId,
                        modernUIVersion: process.env.REACT_APP_VERSION ?? "",
                        fullPage: true,
                        client: ClientType.Embed,
                        timeZone: userCulture.TimeZoneInfo
                    })
                })
                .catch((e: any) => {
                    const date = new Date(Date.now())
                    setError({
                        ...e,
                        currentUser: accounts?.[0].username,
                        dateAndTime: `${date.toLocaleDateString(
                            locale ?? "en"
                        )} ${date.toLocaleTimeString(locale ?? "en")}`,
                        message: e.message
                    })
                })
        }
    }, [])

    useEffect(() => {
        if (runtimeContext) {
            const processId = urlParams.get("processid") ?? ""
            const processInstanceId = urlParams.get("processinstanceid") ?? ""
            const list = urlParams.get("list") ?? ""
            const itemsParam = urlParams.get("items")
            let items: EventItem[] = []
            if (itemsParam) {
                try {
                    items = itemsParam ? JSON.parse(decodeURIComponent(itemsParam)) : []
                } catch (error) {
                    const er = error
                    console.log(er)
                }
            }
            if (processId || processInstanceId) {
                const customParams: any = {}
                urlParams.forEach((value, key) => {
                    if (
                        [
                            "sphosturl",
                            "businessmoduleid",
                            "entityid",
                            "locale",
                            "processid",
                            "list",
                            "items",
                            "businessmodulecontenttype",
                            "entitysitelist",
                            "entitysitelistcontenttype",
                            "folder",
                            "processinstanceid"
                        ].indexOf(key) < 0
                    ) {
                        customParams[key] = value
                    }
                })

                eventBus.runProcess({
                    processInstanceId,
                    processId,
                    businessModuleId,
                    entityId,
                    list,
                    items,
                    parameters: {
                        BusinessModuleId: businessModuleId,
                        BusinessModuleContentType: urlParams.get("businessmodulecontenttype"),
                        EntitySiteList: urlParams.get("entitysitelist"),
                        EntitySiteListContentType: urlParams.get("entitysitelistcontenttype"),
                        Folder: urlParams.get("folder"),
                        ...customParams
                    }
                })
            }
        }
    }, [runtimeContext])

    if (error) {
        if (error.status === 401 || error.status === 403) {
            element = (
                <UnauthorizedError
                    error={error}
                    logout={() => {
                        instance.logoutRedirect()
                    }}
                />
            )
        } else {
            element = <GenericError error={error} />
        }
    } else {
        element = <>{runtimeContext && <Process runtimeContext={runtimeContext} />}</>
    }

    return element
}
